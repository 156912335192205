<template>
    <div v-if="merklisten!==null" class="section wishlist home-wishlist">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-lg-3 col-xl-4">
                    <div class="section-head mt-lg-40">
                        <h3 class="section-head__title">Merklisten</h3>
                        <h4 class="section-head__info">Wir haben Sammlungen zu verschiedenen Themen für dich zusammengestellt. Du findest diese Merklisten im Menü unter “Mehr”.</h4>
                        <a href="/merkliste" title="Mehr Merklisten anzeigen" class="btn mt20 d-none d-lg-block">MEHR ANZEIGEN</a>
                    </div>
                </div>

                <div class="col-12 col-lg-9 col-xl-8">
                    <div class="card-row row mobile--scrolling dropShadowWorkaround merkliste-card">
                        <div class="merklisteCard d-flex align-items-stretch" v-for="(item, index) in merklisten" :key="index">
                            <merkliste-card class="merklisteCard" :color="item.color" :item="item"/>
                        </div>
                    </div>
                </div>
            </div>
            <a href="/merkliste" title="Mehr Merklisten anzeigen" class="btn btn-block mt30 d-lg-none">MEHR ANZEIGEN</a>
        </div>
    </div>
</template>

<script>

  import { getContentsOfType } from '@/api/content';
  import { extractMerklisten } from '@/utils/lupe-helpers';
  import { showLoader, hideLoader } from '@/utils/helpers';

  export default {
    name: "MerklisteSection",
    components: {
        MerklisteCard: () => import('@/components/cards/Merkliste.vue'),
    },
    data() {
        return {
            merklisten: null,
        }
    },
    created() {
      this.getMerklisten();
  }, 
  methods: {
    showLoader,
    hideLoader,
    extractMerklisten,
    getMerklisten() {
        var contentLoader = this.showLoader(contentLoader);
        this.$store.dispatch('loadCollections').then(response => {
            this.merklisten = this.extractMerklisten(response,3);
        })
        .finally(() => {
            contentLoader = this.hideLoader(contentLoader);
        });
    },
}
}
</script>
<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    body[dir="rtl"] {
        .home-wishlist{
            .card:last-child{
                @media (max-width: 991px) {
                    margin-right: 15px;
                }
            }
        }
    }

    a.btn{
        color: #fff;
        max-width: 210px; 

        &:hover{
            color: #C8D400;
            text-decoration: none;
        }
    }

    .merklisteCard{
        margin-right: 0px !important;
    }

    .merkliste-card:last-child {
        padding-right: 15px;
    }

    .dropShadowWorkaround{
        padding-bottom: 10px;
    }

</style>
